.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  min-height: 10vh;
  width: 898px;
  margin: auto;
  font-size: calc(10px + 1vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.App-menu-link {
  text-decoration: none;
  text-align: right;
  display: inline-block;
  float: right;
  margin: 5px;
}

.App-color-picker {
  /* float: right; */
}

.App-color-picker-input {
  display: block;
  box-sizing: border-box;
  width: 200px;
  margin: 5px 0px 0;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #eee;
  outline: none;
  font: inherit;
  text-align: center;
}

.App-network-picker-input {
  display: block;
  box-sizing: border-box;
  width: 150px;
  margin: 5px 0px 0;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #eee;
  outline: none;
  font: inherit;
  text-align: center;
}

.canvas {
  border: 1px solid #000;
  text-align: center;
  margin: auto;
}

.canvasArtBox {
  border: 1px solid #000;
  text-align: center;
  margin: auto;
}

.canvasArtBoxInfo {
  text-align: left;
  height: 200px;
  width: 200px;
  margin-left: 10px;
  display: inline-block;
  position: absolute;
}

.artboxInfoSection {
  display: block;
  width: 200px;
  box-sizing: border-box;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #eee;
  outline: none;
  font: inherit;
  text-transform: capitalize;
  text-align: left;
  overflow-wrap: anywhere;
}

.artBoxButtons {
  text-decoration: none;
  text-align: right;
  display: grid;
}

.layout {
}

.menu {
}

.react-colorful {
  height: 240px;
  width: auto;
}
.react-colorful__saturation {
  border-radius: 4px 4px 0 0;
}
.react-colorful__hue {
  height: 40px;
  border-radius: 0 0 4px 4px;
}
.react-colorful__hue-pointer {
  width: 12px;
  height: inherit;
  border-radius: 0;
}

.artboxalertwidth: {
  max-width: 538px !important;
}

.img-profile {
  height: 200px;
  width: 200px;
}
